import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import SuccessImage from "../images/successMark.svg";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

const ReassignPatient = ({ selectedClinic, providerID, patientID, db }) => {
  const [assignedProvider, setAssignedProvider] = useState(providerID); // State for assigned provider
  const [providers, setProviders] = useState([]); // State for list of providers
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        const providersCollection = collection(db, "providers");
        const providersSnapshot = await getDocs(providersCollection);
        const providersList = providersSnapshot.docs.map(doc => ({
          id: doc.id,
          fullName: `${doc.data().first_name} ${doc.data().last_name}`, // Concatenate first_name and last_name
        }));
        setProviders(providersList);
      } catch (error) {
        console.error("Error fetching providers:", error);
        setErrorMessage("Error fetching providers data.");
      }
    };

    fetchProviders();
  }, [db]);

  const handleProviderChange = (event) => {
    setAssignedProvider(event.target.value);
  };

  const handleSignup = async () => {
    setLoading(true);
    try {
      // Step 1: Update app_patient_clinic_id collection
      const patientDocRef = doc(db, "app_patient_clinic_id", patientID);
      await updateDoc(patientDocRef, {
        assigned_provider_ids: arrayRemove(providerID), // Remove the old provider ID
      });
      await updateDoc(patientDocRef, {
        assigned_provider_ids: arrayUnion(assignedProvider), // Add the new provider ID
      });
  
      // Step 2: Update the old provider's assigned_patients array in assigned_clinics
      const oldProviderDocRef = doc(db, "providers", providerID);
      const oldProviderDocSnap = await getDoc(oldProviderDocRef);
      if (oldProviderDocSnap.exists()) {
        const oldProviderData = oldProviderDocSnap.data();
        const updatedOldAssignedClinics = oldProviderData.assigned_clinics.map((clinic) => {
          if (clinic.clinic_id === selectedClinic) {
            return {
              ...clinic,
              assigned_patients: clinic.assigned_patients.filter((id) => id !== patientID), // Remove the patient ID
            };
          }
          return clinic;
        });
  
        await updateDoc(oldProviderDocRef, {
          assigned_clinics: updatedOldAssignedClinics,
        });
      } else {
        throw new Error("Old provider document does not exist.");
      }
  
      // Step 3: Update the new provider's assigned_patients array in assigned_clinics
      const newProviderDocRef = doc(db, "providers", assignedProvider);
      const newProviderDocSnap = await getDoc(newProviderDocRef);
      if (newProviderDocSnap.exists()) {
        const newProviderData = newProviderDocSnap.data();
        const updatedNewAssignedClinics = newProviderData.assigned_clinics.map((clinic) => {
          if (clinic.clinic_id === selectedClinic) {
            return {
              ...clinic,
              assigned_patients: [...clinic.assigned_patients, patientID], // Append the patient ID
            };
          }
          return clinic;
        });
  
        await updateDoc(newProviderDocRef, {
          assigned_clinics: updatedNewAssignedClinics,
        });
      } else {
        throw new Error("New provider document does not exist.");
      }
  
      setShowSuccess(true);
      setSuccessMessage("Patient reassigned successfully.");
    } catch (error) {
      console.error("Error reassigning patient:", error);
      setErrorMessage("Failed to reassign patient.");
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <Grid
      container
      spacing={0}
      justifyContent="center"
      alignItems="center"
      style={{ padding: "20px" }}
    >
      <Grid item xs={12} container direction="column" alignItems="center">
        <Grid item xs={12} container direction="column" alignItems="center">
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <Typography
              variant="h3"
              align="center"
              className="active-font"
              style={{ color: "black" }}
            >
              Reassign Patient
            </Typography>

            <div style={{ height: "20px" }}></div>

            <FormControl fullWidth style={{ marginTop: "20px" }}>
              <InputLabel
                id="provider-select-label"
                style={{ color: "#9B9B9B" }}
              >
                Assign Provider
              </InputLabel>
              <Select
                className="basefont-regular"
                labelId="provider-select-label"
                id="provider-select"
                value={assignedProvider}
                label="Assign Provider"
                style={{ color: "#6A6A6A" }}
                onChange={handleProviderChange}
              >
                {providers.map((provider) => (
                  <MenuItem
                    key={provider.id}
                    value={provider.id}
                    className="basefont-regular"
                    style={{ color: "#6A6A6A" }}
                  >
                    {provider.fullName} {/* Show full name here */}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {errorMessage && (
              <Typography
                variant="body2"
                style={{
                  color: "#FF4D4F",
                  fontSize: "14px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {errorMessage}
              </Typography>
            )}

            {showSuccess && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={SuccessImage}
                  alt="Success"
                  style={{
                    width: "60px",
                    height: "60px",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  variant="body1"
                  align="center"
                  style={{ color: "#4BD891" }}
                >
                  {successMessage}
                </Typography>
              </div>
            )}

            <Button
              onClick={handleSignup}
              variant="outlined"
              className="active-font"
              style={{
                marginTop: "50px",
                marginBottom: "20px",
                borderColor: "#4BD891",
                color: "black",
                fontSize: "1.0rem",
                minWidth: "140px",
                height: "40px",
              }}
            >
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReassignPatient;
