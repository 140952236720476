import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, collection, getDocs, updateDoc, arrayUnion, arrayRemove, setDoc, onSnapshot } from "firebase/firestore";
import MenuPane from "./MenuPane";
import VitalQueTextField from "../landing-page/VitalQueTextField";
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem'; 
import ListItemText from '@mui/material/ListItemText'; 
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';

const SurveyPicker = ({ open, onClose, onAddSurvey, db }) => {
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchSurveys = async () => {
    setLoading(true);
    setError(null);

    const docRef = doc(db, "surveys", "Teh1GuwrsueEEpMPE8lr"); // Reference to the document
    try {
        const docSnap = await getDoc(docRef); // Fetch the document
        if (docSnap.exists()) {
            const surveyData = docSnap.data();
            console.log("Fetched survey data:", surveyData); // Log fetched data

            // Adjust to match the actual data structure
            const surveysList = Object.keys(surveyData).map(key => {
                const survey = surveyData[key].configuration; // Access the configuration

                // Ensure survey and its questions are defined
                if (survey && survey.questions) {
                    return {
                        id: key,
                        configuration: {
                            name: survey.name || "Unnamed Survey",
                            questions: survey.questions.map(q => ({
                                question: q.question || "No question text",
                                style: q.style || "Unknown style" // Include style property
                            }))
                        }
                    };
                }
                return null;
            }).filter(survey => survey !== null); // Filter out any null surveys

            // Update state with the fetched surveys
            setSurveys(surveysList);
        } else {
            console.log("No such document!");
            setError("No such document found.");
        }
    } catch (err) {
        console.error("Error fetching document: ", err);
        setError(`Error fetching surveys: ${err.message}`);
        alert(`Error fetching surveys: ${err.message}`);
    } finally {
        setLoading(false);
    }
};

useEffect(() => {
  if (open) {
      fetchSurveys(); // Fetch surveys when the dialog is opened
  }
}, [open]);



  return (
      <Dialog open={open} onClose={onClose}>
          <Box padding={2}>
              {loading ? (
                  <Typography>Loading surveys...</Typography>
              ) : error ? (
                  <Typography color="error">{error}</Typography>
              ) : (
                  <List>
                      {surveys.map((survey, index) => (
                          <ListItem button key={index} onClick={() => onAddSurvey(survey)}>
                              <ListItemText
                                  primary={<Typography variant="h6" className="basefont-regular">{survey.configuration.name}</Typography>}
                                  secondary={
                                      <Box marginTop={2}>
                                          {survey.configuration.questions.map((question, qIndex) => (
                                              <Box key={qIndex} marginBottom={1}>
                                                  <Typography variant="body1" className="basefont-regular">
                                                      {`${qIndex + 1}. ${question.question}`}
                                                  </Typography>
                                              </Box>
                                          ))}
                                      </Box>
                                  }
                              />
                          </ListItem>
                      ))}
                  </List>
              )}
          </Box>
      </Dialog>
  );
};

export default SurveyPicker;