import React, { useState, useEffect } from "react";
import "./HomePage.css";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../images/logo.svg";
import Header from "./Header.js";
import Footer from "./Footer.js";
import VitalQueTextField from "./VitalQueTextField.js";
import SuccessImage from "../images/successMark.svg";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const ContactPage = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false); 

  const handleSignup = async () => {
    try {
      setLoading(true); 

      if (!firstName || !lastName || !email || !phoneNumber || !companyName) {
        setErrorMessage("All fields are required.");
        setShowSuccess(false);
        return;
      }
  
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(email)) {
        setErrorMessage("Invalid email format.");
        setShowSuccess(false);
        return;
      }
      
      const phonePattern = /^\d{10}$/;
      if (!phonePattern.test(phoneNumber)) {
        setErrorMessage("Invalid phone number format.");
        setShowSuccess(false);
        return;
      }

      // Authenticate user before uploading data to Firestore
      await signInWithEmailAndPassword(auth, process.env.REACT_APP_LOGIN, process.env.REACT_APP_PASS);

      // Upload data to Firestore
      await addDoc(collection(db, "contacts"), {
        firstName,
        lastName,
        email,
        phoneNumber,
        companyName,
        timestamp: new Date()
      });

      // Clear form fields
      setFirstname("");
      setLastname("");
      setEmail("");
      setPhoneNumber("");
      setCompanyName("");

      // Show success message
      setErrorMessage("");
      setSuccessMessage("Thank you for submitting these details, we will be in touch!");
      setShowSuccess(true);
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage(`Error submitting form. Please try again later. ${error}`);
      setShowSuccess(false);
    } finally {
      setLoading(false); // Set loading to false when form submission ends
    }
  };

  return (
    <Grid
      container
      spacing={0}
      justify="center"
      alignItems="center"
      style={{ minHeight: "100vh", padding: "4px" }}
    >
      <Grid
        item
        xs={12}
        container
        direction="column"
        alignItems="center"
        style={{ marginBottom: "200px" }}
      >
        <Header />
        <Grid item xs={12} container direction="column" alignItems="center" style={{maxWidth: "350px"}}>
          <div style={{ textAlign: "center", marginTop: "150px" }}>
            <img
              src={logo}
              alt="Logo"
              style={{ maxWidth: "100%", height: "auto" }}
            />
            <Typography
              variant="h3"
              align="center"
              className="active-font"
              style={{ color: "black" }}
            >
              Get Started
            </Typography>
            <Typography
              variant="caption"
              align="center"
              className="basefont-regular"
              style={{ color: "black" }}
            >
              After submitting the following details, one of our team members
              will be in contact within 24-48 hours.
            </Typography>

            <div style={{ height: "20px", maxWidth: "300px" }}></div>
            <VitalQueTextField
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstname(e.target.value)}
            />
            <VitalQueTextField
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastname(e.target.value)}
            />
            <VitalQueTextField
              label="Work Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <VitalQueTextField
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <VitalQueTextField
              label="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />

            {showSuccess || errorMessage ? (
              <div>
                {errorMessage ? (
                  <div>
                    <Typography
                      variant="body1"
                      align="center"
                      className="basefont-thin !important"
                      style={{ color: "red" }}
                    >
                      {errorMessage}
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <img
                      src={SuccessImage}
                      alt="Success"
                      style={{ maxWidth: "50px", height: "auto" }}
                    />
                    <Typography
                      variant="body1"
                      align="center"
                      className="basefont-thin !important"
                      style={{ color: "green" }}
                    >
                      {successMessage}
                    </Typography>
                  </div>
                )}
              </div>
            ) : null}

              <Button
              variant="outlined"
              className="active-font"
              onClick={handleSignup}
              style={{
                marginTop: "50px",
                marginBottom: "20px",
                borderColor: "#4BD891",
                color: "black",
                fontSize: "1.5rem",
                minWidth: "180px",
              }}
              disabled={loading} // Disable button when loading
            >
              {loading ? "Submitting..." : "Get Started"}
            </Button>
          </div>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default ContactPage;
