import React, { useState } from "react";
import { Grid, Typography, Button, Checkbox } from "@mui/material";
import logo from "../images/logo.svg";
import Header from "./Header.js";
import Footer from "./Footer.js";
import VitalQueTextField from "./VitalQueTextField.js";
import { PhoneMissed } from "@mui/icons-material"; 

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc, getDocs } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };
  
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);
  const auth = getAuth(app);
  
  const OptIn = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [optIn, setOptIn] = useState(false);
    const [consentChecked, setConsentChecked] = useState(false);
    const phonePattern = /^\d{10}$/;
  
    const uploadDataToFirestore = async (phoneNumber, consented) => {
      try {
        // Add a document to the 'PhoneNumbers' collection with the phone number as the document ID
        await addDoc(collection(db, "PhoneNumbers"), {
          phoneNumber: phoneNumber,
          consented: consented,
        });
        console.log("Phone number added to Firestore:", phoneNumber);
      } catch (error) {
        console.error('Error adding phone number to Firestore:', error);
      }
    };
  
    const handleOptIn = async () => {
      try {
        // Sign in with email and password
        await signInWithEmailAndPassword(auth, process.env.REACT_APP_LOGIN, process.env.REACT_APP_PASS);
  
        // Check if consent agreement is checked
        if (!consentChecked) {
          console.log("Please agree to the terms above.");
          return;
        }
  
        // Check if phone number is valid
        if (!phonePattern.test(phoneNumber)) {
          console.log("Invalid phone number format. Please enter a 10-digit phone number.");
          return;
        }
  
        // If both conditions are met, set optIn to true
        setOptIn(true);
        
        // If authentication is successful and other conditions are met, perform Firestore upload
        await uploadDataToFirestore(phoneNumber, true);
      } catch (error) {
        console.error('Error signing in:', error);
      }
    };

  return (
    <Grid
      container
      spacing={0}
      justify="center"
      alignItems="center"
      style={{ minHeight: "100vh", padding: "4px" }}
    >
      <Grid
        item
        xs={12}
        container
        direction="column"
        alignItems="center"
        style={{ marginBottom: "200px" }}
      >
        <Grid item xs={12} container direction="column" alignItems="center">
          <div style={{ textAlign: "center", marginTop: "150px" }}>
            <img
              src={logo}
              alt="Logo"
              style={{ maxWidth: "100%", height: "auto" }}
            />
            <Typography
              variant="h1"
              align="center"
              className="active-font"
              style={{ color: "#9B9B9B" }}
            >
              Vital<span style={{ color: "#4BD891" }}>Que</span>
            </Typography>
          </div>
          {/* Consent Form */}
          <div
            style={{ marginTop: "20px", maxWidth: "350px", textAlign: "left" }}
          >
            <Typography variant="h6" component="h2" gutterBottom>
              Purpose
            </Typography>
            <Typography variant="body1" gutterBottom>
              The purpose of this form is to obtain your informed consent for
              the medical services provided by RTM Care Coordination, PC, RTM
              Care Coordination by VitalQue.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom>
              Background On Our Services
            </Typography>
            <Typography variant="body1" gutterBottom>
              Care may be provided by Group staff including the medical doctor,
              nurse, or other staff person. Care may include, but is not limited
              to, obtaining a medical history, performing a physical
              examination, recommending clinical observation, and providing
              treatment as needed. Treatment may include an order for remote
              patient monitoring (“RTM”) as well as providing RTM services.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom>
              Benefits And Risks Of Our Services
            </Typography>
            <Typography variant="body1" gutterBottom>
              The services provided by VitalQue can be an important part of your
              care during your stay. Early detection and intervention may assist
              with avoiding serious clinical events such as respiratory failure,
              complicated cardio-pulmonary conditions, and emergencies like
              falls and injuries. Early detection and intervention may also
              avoid unnecessary hospitalizations. Despite these potential
              benefits, as with any other medical treatment, medicine is not an
              exact science and diagnosis, and treatment may involve risk of
              injury or even death.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom>
              Not For Emergencies
            </Typography>
            <Typography variant="body1" gutterBottom>
              RTM services do not include, and VitalQue does not provide active
              patient monitoring. VitalQue's RTM services are for retrospective
              analysis only and VitalQue does NOT provide alarms for timely
              response in life-threatening situations.
            </Typography>

            <Typography variant="h6" component="h2" gutterBottom>
              Consent
            </Typography>
            <Typography variant="body1" gutterBottom>
              I hereby acknowledge that I am aware of all potential risks
              associated with RTM treatment, including use of the System and
              telemedicine. I acknowledge that no guarantees have been made to
              me regarding the result of examination or treatment by VitalQue. I
              have made the medical doctor and medical staff aware of all my
              known health conditions, allergies, and medications I am taking,
              including herbal medications/supplements. I consent to treatment
              by VitalQue. I understanding I have the right to withdraw this consent
              at any time. My signature below verifies that I that I have read
              all of the information contained in this Consent Form and that I
              have asked questions about anything I have not understood up to
              this point.
            </Typography>
            <Checkbox
              checked={consentChecked}
              onChange={(e) => setConsentChecked(e.target.checked)}
              style={{color:"#4BD891"}}
            />
            <Typography variant="body1">
              By checking this box you agree to the terms above.
            </Typography>
            <div style={{ marginTop: "50px", maxWidth: "320px" }} /> 
            <Typography variant="h5" gutterBottom>
              Opt-in for SMS Notifications
            </Typography>

            <Typography variant="body1" gutterBottom>
              Enter your phone number below to receive SMS notifications:
            </Typography>
             <VitalQueTextField
              label="Mobile Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <Button
               variant="outlined"
               className="basefont-bold"
               style={{
                 marginTop: "10px",
                 color: "black", // Outline color
                 borderColor: "#4BD891", // Outline color
               }}
              onClick={handleOptIn}
              disabled={!phoneNumber || optIn}
            >
              Finish
            </Button>
            {optIn && (
            <Grid item xs={12}>
              <Typography variant="body1" align="center" color="success">
                Your consent is saved you can now exit this page. Thank you!
              </Typography>
            </Grid>
          )}

          {/* Error Messages */}
          {!consentChecked && (
            <Typography variant="body1" style={{ color: "red" }}>
              Must agree to the terms above to continue.
            </Typography>
          )}

          {!phonePattern.test(phoneNumber) && (
            <Typography variant="body1" style={{ color: "red" }}>
              Please enter your 10-digit mobile phone number.
            </Typography>
          )}
          </div>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default OptIn;
