import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Box, Divider, RadioGroup, FormControlLabel, Radio, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import VitalQueTextField from "../landing-page/VitalQueTextField.js";
import SuccessImage from "../images/successMark.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { addDoc, collection, updateDoc, doc, setDoc, getDoc, arrayUnion, arrayRemove, getDocs } from "firebase/firestore";

const PatientIntake = ({ selectedClinic, providerID, db }) => {
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [groupID, setGroupID] = useState("");
  const [payor, setPayor] = useState("");
  const [subscriberID, setSubscriberID] = useState("");
  const [clinicName, setClinicName] = useState(""); // New state for clinic name
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [patientEmail, setPatientEmail] = useState("");
  const [patientPassword, setPatientPassword] = useState("");
  const [assignedProvider, setAssignedProvider] = useState(providerID); // State for assigned provider
  const [providers, setProviders] = useState([]); // State for list of providers

  useEffect(() => {
    const fetchClinicData = async () => {
      try {
        if (selectedClinic) {
          const clinicDoc = doc(db, "clinics", selectedClinic);
          const clinicSnapshot = await getDoc(clinicDoc);

          if (clinicSnapshot.exists()) {
            setClinicName(clinicSnapshot.data().name || "Unknown Clinic");
          } else {
            setErrorMessage("Clinic not found.");
          }
        }
      } catch (error) {
        console.error("Error fetching clinic data:", error);
        setErrorMessage("Error fetching clinic data.");
      }
    };

    const fetchProviders = async () => {
      try {
        const providersCollection = collection(db, "providers");
        const providersSnapshot = await getDocs(providersCollection);
        const providersList = providersSnapshot.docs.map(doc => ({
          id: doc.id,
          fullName: `${doc.data().first_name} ${doc.data().last_name}`, // Concatenate first_name and last_name
        }));
        setProviders(providersList);
      } catch (error) {
        console.error("Error fetching providers:", error);
        setErrorMessage("Error fetching providers data.");
      }
    };

    fetchClinicData();
    fetchProviders();
  }, [selectedClinic, db]);

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleProviderChange = (event) => {
    setAssignedProvider(event.target.value);
  };

  const validateFields = () => {
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      phoneNumber.trim() === "" ||
      gender.trim() === "" ||
      !selectedDate ||
      groupID.trim() === "" ||
      payor.trim() === "" ||
      subscriberID.trim() === "" ||
      patientEmail.trim() === "" ||
      patientPassword.trim() === "" ||
      assignedProvider.trim() === ""
    ) {
      setErrorMessage("All fields are required.");
      return false;
    }

    // Validate phone number format
    const phonePattern = /^\d{10}$/;
    if (!phonePattern.test(phoneNumber)) {
      setErrorMessage("Invalid phone number format.");
      return false;
    }

    return true;
  };

  const handleSignup = async () => {
    const isValid = validateFields();
    if (isValid) {
      setLoading(true);
      try {
        const auth = getAuth();
        // Step 1: Create the patient account with email/password
        const userCredential = await createUserWithEmailAndPassword(auth, patientEmail, patientPassword);
        const uid = userCredential.user.uid;
  
        // Step 2: Add the patient to the clinic's subcollection
        const patientDocRef = doc(db, `clinics/${selectedClinic}/patients`, uid);
        await setDoc(patientDocRef, {
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber,
          gender: gender,
          date_of_birth: selectedDate,
          group_id: groupID,
          payor: payor,
          subscriber_id: subscriberID,
          assigned_exercises: [],
          assigned_surveys: [],
          messages: [],
          survey_responses: [],
          clinic_id: selectedClinic,
          clinic_name: clinicName,
        });
  
        // Step 3: Create a document in app_patient_clinic_id collection
        const appPatientDocRef = doc(db, `app_patient_clinic_id/${uid}`);
        await setDoc(appPatientDocRef, {
          assigned_provider_ids: [assignedProvider],
          clinic_id: selectedClinic,
        });
  
        // Step 4: Update the provider's assigned_clinics array
        const providerDocRef = doc(db, "providers", assignedProvider);
        const providerDoc = await getDoc(providerDocRef);
        if (providerDoc.exists()) {
          const providerData = providerDoc.data();
          const assignedClinics = providerData.assigned_clinics || [];
          const clinicIndex = assignedClinics.findIndex(clinic => clinic.clinic_id === selectedClinic);
  
          if (clinicIndex >= 0) {
            const clinicData = assignedClinics[clinicIndex];
            const updatedAssignedPatients = [...(clinicData.assigned_patients || []), uid];
            const updatedAssignedClinics = assignedClinics.map((clinic, index) =>
              index === clinicIndex
                ? { ...clinic, assigned_patients: updatedAssignedPatients }
                : clinic
            );
            
            await updateDoc(providerDocRef, {
              assigned_clinics: updatedAssignedClinics,
            });
          } else {
            console.error("Clinic ID not found in provider's assigned_clinics.");
          }
        } else {
          console.error("Provider not found.");
        }
  
        // Clear form fields and display success message
        setFirstname("");
        setLastname("");
        setPhoneNumber("");
        setGender("");
        setSelectedDate(null);
        setGroupID("");
        setPayor("");
        setSubscriberID("");
        setPatientEmail("");
        setPatientPassword("");
        setAssignedProvider(providerID); // Reset provider to default or handle as needed
        setErrorMessage("");
        setSuccessMessage("Patient details submitted successfully.");
        setShowSuccess(true);
  
      } catch (error) {
        console.error("Error submitting patient details:", error);
        setErrorMessage("Error submitting. Please try again.");
        setShowSuccess(false); // Ensure success message is hidden
      } finally {
        setLoading(false);
      }
    } else {
      setShowSuccess(false); // Hide success message if validation fails
    }
  };

  return (
    <Grid
      container
      spacing={0}
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh", padding: "4px" }}
    >
      <Grid
        item
        xs={12}
        container
        direction="column"
        alignItems="center"
      >
        <Grid item xs={12} container direction="column" alignItems="center">
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <Typography
              variant="h3"
              align="center"
              className="active-font"
              style={{ color: "black" }}
            >
              Patient Intake
            </Typography>
            <Typography
              variant="caption"
              align="center"
              className="basefont-regular"
              style={{ color: "black" }}
            >
              Please submit the following details to add the patient to VitalQue's system.
            </Typography>

            <div style={{ height: "20px" }}></div>
            <Grid item xs={12}>
              <Box mt={2}>
                <Divider
                  sx={{
                    borderColor: "#4BD891",
                    borderWidth: "2px",
                    marginBottom: "20px",
                  }}
                />
              </Box>
            </Grid>
            <Typography
              variant="body"
              className="basefont-regular"
              gutterBottom
            >
              Enter Patient Information
            </Typography>
            <VitalQueTextField
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstname(e.target.value)}
            />
            <VitalQueTextField
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastname(e.target.value)}
            />
            <VitalQueTextField
              label="Patient Email"
              value={patientEmail}
              onChange={(e) => setPatientEmail(e.target.value)}
            />
            <VitalQueTextField
              label="Patient Password"
              value={patientPassword}
              onChange={(e) => setPatientPassword(e.target.value)}
            />
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={gender}
              onChange={handleGenderChange}
              className="basefont-regular"
            >
              <FormControlLabel
                value="male"
                control={<Radio />}
                label={
                  <Typography
                    className="basefont-regular"
                    style={{ color: "#6A6A6A" }}
                  >
                    Male
                  </Typography>
                }
              />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label={
                  <Typography
                    className="basefont-regular"
                    style={{ color: "#6A6A6A" }}
                  >
                    Female
                  </Typography>
                }
              />
            </RadioGroup>

            <Typography
              variant="body"
              align="center"
              className="basefont-regular"
              style={{ color: "#6A6A6A" }}
            >
              Date of Birth
            </Typography>
            <div style={{ padding: "15px 0" }}>
              <DatePicker selected={selectedDate} onChange={handleDateChange} />
            </div>
            <VitalQueTextField
              label="Mobile Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <VitalQueTextField
              label="Group ID"
              value={groupID}
              onChange={(e) => setGroupID(e.target.value)}
            />
            <VitalQueTextField
              label="Payor"
              value={payor}
              onChange={(e) => setPayor(e.target.value)}
            />
            <VitalQueTextField
              label="Subscriber ID"
              value={subscriberID}
              onChange={(e) => setSubscriberID(e.target.value)}
            />

            <FormControl fullWidth style={{marginTop: "20px"}}>
              <InputLabel id="provider-select-label" style={{color: "#9B9B9B"}}>Assign Provider</InputLabel>
              <Select
              className="basefont-regular"
                labelId="provider-select-label"
                id="provider-select"
                value={assignedProvider}
                label="Assign Provider"
                style={{color: "#6A6A6A"}}
                onChange={handleProviderChange}
              >
                {providers.map((provider) => (
                  <MenuItem key={provider.id} value={provider.id} className="basefont-regular" style={{color: "#6A6A6A"}}>
                    {provider.fullName} {/* Show full name here */}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {errorMessage && (
              <Typography
                variant="body2"
                style={{
                  color: "#FF4D4F",
                  fontSize: "14px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {errorMessage}
              </Typography>
            )}

            {showSuccess && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={SuccessImage}
                  alt="Success"
                  style={{
                    width: "60px",
                    height: "60px",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  variant="body1"
                  align="center"
                  style={{ color: "#4BD891" }}
                >
                  {successMessage}
                </Typography>
              </div>
            )}

            <Button
            onClick={handleSignup}
           variant="outlined"
           className="active-font"
           style={{
               marginTop: "50px",
               marginBottom: "20px",
               borderColor: "#4BD891",
               color: "black",
               fontSize: "1.0rem",
               minWidth: "140px",
               height: "40px",
               }}>
            
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PatientIntake;
