import React, { useEffect } from "react";
import "./HomePage.css";
import { Grid, Typography, Button, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../images/logo.svg";
import Header from "./Header.js";
import Footer from "./Footer.js";
import HeaderImage from "../images/000.png";
import Slide1Image from "../images/001.png";
import Slide2Image from "../images/002.png";
import Slide3Image from "../images/003.png";
import FooterImage from "../images/004.png";
import AnimatedVideo from "../images/VitalQue-Home.mp4";

const HomePage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  return (
    <Grid
      container
      spacing={0}
      justifyContent="center"
      alignItems="center"
      className="body"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={12} container direction="column" alignItems="center" style={{ marginBottom: "100px" }}>
        <Header />
      </Grid>

      <Grid item xs={12} container direction="column" alignItems="center">
        <div style={{ textAlign: "center" }}>
          <img src={logo} alt="Logo" style={{ maxWidth: "100%", height: "auto" }} />
          <Typography variant="h1" align="center" className="active-font" style={{ color: "#9B9B9B" }}>
            Vital<span style={{ color: "#4BD891" }}>Que</span>
          </Typography>
          <Typography variant="h4" align="center" className="active-font" style={{ color: "#9B9B9B" }}>
            Elevate Clinic Care With Remote Therapeutic Monitoring.
          </Typography>
        </div>
        <Button
          component={Link}
          to="/getstarted"
          variant="outlined"
          className="active-font"
          style={{
            marginTop: "10px",
            color: "black", // Outline color
            borderColor: "#4BD891", // Outline color
            fontSize: "1.5rem",
          }}
        >
          Get Started
        </Button>
        <Button
          component={Link}
          to="/login"
          variant="text"
          className="active-font"
          style={{
            marginTop: "10px",
            color: "black", // Text color
            border: "none", // Remove border
            backgroundColor: "transparent", // Transparent background
            fontSize: "1.5rem",
            textDecoration: "underline",
          }}
        >
         Login
        </Button>
      </Grid>

      <Grid item xs={12} container direction="column" alignItems="center">
        <div
          style={{
            maxWidth: "80%", // Adjust the maxWidth as needed
            position: "relative",
            marginTop: "20px",
            marginBottom: "50px",
          }}
        >
          <Link to="/getstarted">
          <video width="100%" height="650px"  
               onEnded={(e) => {
                e.target.pause(); // Pause the video
                setTimeout(() => {
                  e.target.play(); // Resume the video after the specified time
                }, 5000);
              }}
            autoPlay
            muted
            playsInline
            style={{ cursor: "pointer", borderRadius: "15px" }}>
            <source src={AnimatedVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          </Link>
        </div>
      </Grid>

      <Grid item xs={12} container direction="column" alignItems="center">
        <Typography variant="h3" align="center" className="active-font" style={{ color: "black", marginTop: "150px" }}>
          What Do We Do?
        </Typography>
        <Typography
          variant="h6"
          align="center"
          className="basefont-medium"
          style={{ color: "black", marginTop: "20px", maxWidth: "535px", padding: "10px", marginBottom: "40px" }}
        >
          Our mission is to provide comprehensive remote therapeutic monitoring solutions to clinics, enabling them to deliver personalized care to their patients.
        </Typography>
        <Divider
          variant="middle"
          flexItem
          style={{
            marginTop: "25px",
            width: "35%",
            margin: "0 auto",
            backgroundColor: "#9B9B9B",
            borderBottomWidth: "1px"
          }}
        />
      </Grid>

      {/* Slide Images */}
      <Grid container spacing={2} justifyContent="center" style={{ marginTop: "50px", padding: "30px" }}>
        <Grid item xs={12} sm={6} md={4} container direction="column" alignItems="center">
          <img src={Slide1Image} alt="Slide 1" style={{ maxWidth: "100%", height: "auto", marginBottom: "20px" }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} container direction="column" alignItems="center">
          <img src={Slide2Image} alt="Slide 2" style={{ maxWidth: "100%", height: "auto", marginBottom: "20px" }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} container direction="column" alignItems="center">
          <img src={Slide3Image} alt="Slide 3" style={{ maxWidth: "100%", height: "auto", marginBottom: "20px" }} />
        </Grid>
      </Grid>

      <Grid item xs={12} container direction="column" alignItems="center">
        {/* Footer Image */}
        <Grid item xs={12} container justifyContent="center">
          <img src={FooterImage} alt="Footer" style={{ maxWidth: "100%", height: "auto", padding: "10px" }} />
        </Grid>
        <Button
          component={Link}
          to="/getstarted"
          variant="outlined"
          className="active-font"
          style={{
            marginTop: "",
            color: "black", // Outline color
            borderColor: "#4BD891", // Outline color
            fontSize: "1.5rem",
            marginBottom: "50px",
          }}
        >
          Get Started
        </Button>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default HomePage;
