// Import necessary components
import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import TextField from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../images/logo.svg';
import Header from './Header';
import Footer from './Footer';
import VitalQueTextField from './VitalQueTextField';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, doc, getDoc  } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const LoginPage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      // Firebase authentication
      const userCredential = await signInWithEmailAndPassword(auth, username, password);
      const user = userCredential.user;

      // Store user UID to session storage (or a better state management solution)
      sessionStorage.setItem('userUID', user.uid);

      // Fetch user role from Firestore
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const userRole = userData.role; // Assuming the role is stored in the 'role' field

        // Navigate based on the user's role
        if (userRole === 'provider') {
          navigate("/providerhome");
        } else if (userRole === 'admin') {
          navigate("/adminhome");
        } else if (userRole === 'partner') {
          navigate("/partnerhome");
        } else {
          console.error('Unknown role:', userRole);
          setShowError(true); // Show error if role is unknown
        }
      } else {
        console.error('No user document found!');
        setShowError(true); // Show error if no user document is found
      }
    } catch (error) {
      console.error('Login error:', error);
      // Show error message if login fails
      setShowError(true);
    }
  };

  return (
    <Grid
      container
      spacing={0}
      justifyContent="center"
      alignItems="center"
      className="body"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={12} container direction="column" alignItems="center" style={{ marginBottom: '100px' }}>
        <Header />
      </Grid>
      <Grid item xs={12} container direction="column" alignItems="center">
        <div style={{ textAlign: 'center' }}>
          <img src={logo} alt="Logo" style={{ maxWidth: '100%', height: 'auto' }} />
          <Typography variant="h1" align="center" className="active-font" style={{ color: '#9B9B9B' }}>
            Vital<span style={{ color: '#4BD891' }}>Que</span>
          </Typography>
        </div>
          <div style={{ textAlign: 'center' }}>
            <VitalQueTextField 
              label="Email" 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
              type="email" // Change input type to email
            />
            <VitalQueTextField 
              label="Password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              type="password" // Change input type to password
            />
            <Link to="/forgotpassword" style={{ textDecoration: 'none', marginTop: '10px' }}>
              <Typography variant="body1" align="center" className='basefont-thin' style={{ color: 'gray' }}>
                Forgot your password?
              </Typography>
            </Link>

            {showError && (
              <div style={{ height: '20px' }}>
                <Typography variant="caption" align="center" className='basefont-thin' style={{ color: 'red', marginTop: '10px' }}>
                  Invalid username or password
                </Typography>
              </div>
            )}

            <Button
              variant="outlined"
              className="active-font"
              onClick={handleLogin}
              style={{
                marginTop: "50px",
                marginBottom: "170px",
                borderColor: "#4BD891",
                color: "black", // Text color
                fontSize: "1.5rem",
                minWidth: "180px",
              }}
            >
              Login
            </Button>
          </div>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default LoginPage;