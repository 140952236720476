import React from 'react';
import TextField from '@mui/material/TextField';
import "./HomePage.css";

const VitalQueTextField = ({ label, value, onChange, type = "text", classes }) => {
  return (
    <div>
      <TextField
        label={label}
        variant="outlined"
        margin="normal"
        type={type}
        fullWidth
        value={value}
        onChange={onChange}
        style={{ minWidth: '320px'}}
        sx={{
          minWidth: '320px',
          '& .MuiInputLabel-root': {
            color: '#6A6A6A !important',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#9B9B9B !important',
            },
            '& input': {
              color: '#6A6A6A',
            },
          },
        }}
        InputLabelProps={{
          classes: { 
            focused: '#6A6A6A !important',
            root: 'basefont-thin'
          },
        }}
        InputProps={{
          classes: {
            notchedOutline:  '#9B9B9B !important',
            root: 'basefont-regular',
          },
        }}
      />
    </div>
  );
};
export default VitalQueTextField;