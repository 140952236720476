import React, { useState, useEffect, useRef } from "react";
import { doc, updateDoc, arrayUnion, onSnapshot, Timestamp } from "firebase/firestore";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import VitalQueTextField from "../landing-page/VitalQueTextField";
import Button from "@mui/material/Button";

const MessagingComponent = ({ patient, db, clinicId }) => {
    const [messageText, setMessageText] = useState("");
    const [messages, setMessages] = useState([]);
    const messagesContainerRef = useRef(null); // Reference to the messages container

    // Construct the patient document reference
    const patientRef = doc(db, "clinics", clinicId, "patients", patient.id);

    useEffect(() => {
        // Set up a Firestore listener to fetch messages in real-time
        const unsubscribe = onSnapshot(patientRef, (doc) => {
            if (doc.exists()) {
                const data = doc.data();
                setMessages(data.messages || []);
            }
        });

        // Clean up the listener on unmount
        return () => unsubscribe();
    }, [db, clinicId, patient.id]);

    // Scroll to the bottom of the message list whenever messages change
    useEffect(() => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSendMessage = async () => {
        if (!messageText.trim()) {
            alert("Please enter a message before sending.");
            return;
        }

        // Create the message object with Firestore Timestamp
        const message = {
            app_inbound: true,
            message: messageText,
            sender: "PT",
            timestamp: Timestamp.now(), // Use Firestore's Timestamp for consistency
        };

        try {
            // Update the patient document with the new message
            await updateDoc(patientRef, {
                messages: arrayUnion(message),
            });

            // Clear the input field after sending the message
            setMessageText("");

            console.log("Message sent successfully!");
        } catch (error) {
            console.error("Error sending message: ", error);
        }
    };

    return (
        <Box sx={{ marginTop: 4 }}>
            <Typography variant="h6" className="basefont-regular">
                Conversation with {patient.first_name} {patient.last_name}
            </Typography>
            
            {/* Display messages */}
            <Box
                ref={messagesContainerRef} // Assign the ref to the messages container
                sx={{ maxHeight: 300, overflowY: "auto", marginBottom: 2, display: 'flex', flexDirection: 'column' }}
            >
                {messages.map((msg, index) => (
                    <Paper
                        key={index}
                        sx={{
                            margin: 1,
                            padding: 1,
                            maxWidth: "60%",
                            alignSelf: msg.app_inbound ? "flex-end" : "flex-start",
                            backgroundColor: msg.app_inbound ? "#4BD891" : "lightgrey",
                            textAlign: msg.app_inbound ? "right" : "left",
                        }}
                    >
                        <Typography variant="body2" color="textSecondary" className="basefont-regular">
                            {msg.sender}
                        </Typography>
                        <Typography variant="body1" className="basefont-regular">
                            {msg.message}
                        </Typography>
                        {msg.timestamp && (
                            <Typography variant="caption" className="basefont-regular" color="textSecondary">
                                {msg.timestamp.toDate().toLocaleString()} {/* Convert Firestore Timestamp to Date */}
                            </Typography>
                        )}
                    </Paper>
                ))}
            </Box>

            {/* Input for sending new messages */}
            <VitalQueTextField
                fullWidth
                label="Type your message"
                variant="outlined"
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                sx={{ marginBottom: 2 }}
            />
            <Button 
                variant="outlined" 
                className="active-font"
                style={{
                    marginTop: "2px",
                    color: "black", // Outline color
                    borderColor: "#4BD891", // Outline color
                    fontSize: "1.2rem",
                }} 
                onClick={handleSendMessage}
            >
                Send
            </Button>
        </Box>
    );
};

export default MessagingComponent;
