import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, collection, getDocs, query, where } from "firebase/firestore";
import MenuPane from "./MenuPane";
import VitalQueTextField from "../landing-page/VitalQueTextField";
import PatientOverview from "./patientoverview";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PatientIntake from "./patientintake";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const ProviderHome = () => {
  const [providerName, setProviderName] = useState('');
  const [clinics, setClinics] = useState([]);
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [openPatientIntake, setOpenPatientIntake] = useState(false);

  useEffect(() => {
    const fetchProviderData = async () => {
      const userUID = sessionStorage.getItem("userUID");
      if (!userUID) return;

      try {
        const docRef = doc(db, "providers", userUID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const providerData = docSnap.data();
          const assignedClinics = providerData.assigned_clinics;

          const clinicIds = assignedClinics.map(clinic => clinic.clinic_id);
          const patientIds = assignedClinics.flatMap(clinic => clinic.assigned_patients);

          if (clinicIds.length > 0) {
            const clinicsCollectionRef = collection(db, "clinics");
            const clinicsQuery = query(clinicsCollectionRef, where("__name__", "in", clinicIds));
            const clinicsSnapshot = await getDocs(clinicsQuery);
            const clinicsList = clinicsSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));

            setClinics(clinicsList);

            if (!selectedClinic && clinicsList.length > 0) {
              setSelectedClinic(clinicsList[0].id);
            }
          }

          if (patientIds.length > 0 && selectedClinic) {
            const patientsCollectionRef = collection(db, `clinics/${selectedClinic}/patients`);
            const patientsQuery = query(patientsCollectionRef, where("__name__", "in", patientIds));
            const patientsSnapshot = await getDocs(patientsQuery);
            const patientsList = patientsSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            setPatients(patientsList);
            setFilteredPatients(patientsList);
          } else {
            setPatients([]);
            setFilteredPatients([]);
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching provider data:", error);
      }
    };

    fetchProviderData();
  }, [selectedClinic]);

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setFilteredPatients(
      patients.filter((patient) =>
        `${patient.first_name} ${patient.last_name}`.toLowerCase().includes(query)
      )
    );
  };

  const handlePatientClick = (patient) => {
    setSelectedPatient(patient);
  };

  return (
    <Grid container direction="column" style={{ minHeight: "100vh" }}>
      {/* MenuPane */}
      <MenuPane />

      {/* Clinics, Patients, and Patient Details */}
      <Grid item container direction="column" xs={12} md={10} style={{ marginTop: "75px", padding: "16px" }}>
        {/* Clinics and Patients Section */}
        <Grid item container spacing={3}>
          {/* Clinics Section */}
          <Grid item xs={12} md={3}>
            <Typography variant="h5" className="basefont-regular" style={{ marginBottom: "20px" }}>
              Clinics
            </Typography>
            {clinics.map((clinic) => (
              <Box
                key={clinic.id}
                onClick={() => {
                  setSelectedClinic(clinic.id);
                  setSelectedPatient(null);
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px",
                  marginBottom: "10px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  backgroundColor: selectedClinic === clinic.id ? '#4BD891' : 'transparent',
                  '&:hover': { backgroundColor: selectedClinic === clinic.id ? '#4BD891' : '#f0f0f0' }
                }}
              >
                <Typography variant="body1" className="basefont-regular">{clinic.name}</Typography>
                <ArrowForwardIosIcon fontSize="small" />
              </Box>
            ))}
          </Grid>

          {/* Patients Section */}
          <Grid item xs={12} md={3}>
            <Typography variant="h5" className="basefont-regular" style={{ marginBottom: "20px" }}>
              Patients
            </Typography>
            <Button
              onClick={() => setOpenPatientIntake(true)}
              variant="outlined"
              className="active-font"
              style={{
                marginBottom: "20px",
                borderColor: "#4BD891",
                color: "black",
                fontSize: "1.0rem",
                minWidth: "140px",
                height: "40px",
              }}
            >
              Add New Patient
            </Button>
            {selectedClinic && (
              <>
                <VitalQueTextField
                  fullWidth
                  variant="outlined"
                  label="Search Patients"
                  onChange={handleSearchChange}
                  sx={{ marginBottom: '10px' }}
                />
                {filteredPatients.map((patient) => (
                  <Box
                    key={patient.id}
                    onClick={() => handlePatientClick(patient)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px",
                      marginBottom: "10px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      '&:hover': { backgroundColor: '#f0f0f0' }
                    }}
                  >
                    <Typography variant="body1" className="basefont-regular">{`${patient.first_name} ${patient.last_name}`}</Typography>
                  </Box>
                ))}
              </>
            )}
          </Grid>

          {/* Patient Overview Section */}
          <Grid item xs={12} md={6}>
            {selectedPatient ? (
              <PatientOverview patient={selectedPatient} clinicId={selectedClinic} db={db} />
            ) : (
              <Typography variant="h5" className="basefont-regular">
                Select a patient to view details.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Modal for New Patient Intake */}
      <Dialog
      open={openPatientIntake}
      onClose={() => setOpenPatientIntake(false)}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth="md" // Limits the maximum width to a medium size
      fullWidth // Ensures the dialog takes full width for responsiveness
      >
      <DialogContent
        sx={{
      maxHeight: "80vh", // Max height to allow scrolling
      overflowY: "auto", // Enables scrolling when content overflows
        }}
      >
      <PatientIntake selectedClinic={selectedClinic} providerID={sessionStorage.getItem("userUID")} db={db} closeModal={() => setOpenPatientIntake(false)} />
      </DialogContent>
      <DialogActions>
    <Button onClick={() => setOpenPatientIntake(false)}    variant="outlined"
              className="active-font"
              style={{
                marginBottom: "20px",
                borderColor: "#4BD891",
                color: "black",
                fontSize: "1.0rem",
                minWidth: "140px",
                height: "40px",
              }}>
      Cancel
    </Button>
  </DialogActions>
</Dialog>


    </Grid>
  );
};

export default ProviderHome;
