import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, collection, getDocs, updateDoc,  arrayUnion, arrayRemove, setDoc, onSnapshot } from "firebase/firestore";
import MenuPane from "./MenuPane";
import VitalQueTextField from "../landing-page/VitalQueTextField";
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem'; 
import ListItemText from '@mui/material/ListItemText'; 
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import ExercisePicker from "./exercisepicker";
import SurveyPicker from "./surveypicker";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MessagingComponent from "./messaging";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ReassignPatient from "./reassignpatient";


const PatientOverview = ({ patient, clinicId, db }) => {
    const [isExercisePickerOpen, setExercisePickerOpen] = useState(false);
    const [assignedExercises, setAssignedExercises] = useState(patient.assigned_exercises);
    const [assignedSurveys, setAssignedSurveys] = useState(patient.assigned_surveys);
    const [isSurveyPickerOpen, setSurveyPickerOpen] = useState(false);
    const [unsubscribe, setUnsubscribe] = useState(null);
    const [openPatientReassign, setOpenPatientReassign] = useState(false);
  
  
    useEffect(() => {
      if (patient && clinicId) {
        // Reference to the specific patient document
        const patientRef = doc(db, "clinics", clinicId, "patients", patient.id);
  
        // Set up a real-time listener for the patient document
        const unsubscribeFromSnapshot = onSnapshot(patientRef, (docSnap) => {
          if (docSnap.exists()) {
            const { assigned_exercises = [], assigned_surveys = [] } = docSnap.data();
            setAssignedExercises(assigned_exercises);
            setAssignedSurveys(assigned_surveys);
          } else {
            console.log("No such document!");
          }
        }, (error) => {
          console.error("Error fetching document: ", error);
        });
  
        // Store the unsubscribe function in state
        setUnsubscribe(() => unsubscribeFromSnapshot);
  
        // Clean up the listener when the component unmounts or patient changes
        return () => {
          if (unsubscribe) {
            unsubscribe();
          }
        };
      }
    }, [patient, clinicId]);
  
    const handleAddExercise = async (exercise) => {
      // Validate clinicId and patient.id
      if (!clinicId || !patient.id) {
          console.error("Invalid clinicId or patient.id");
          return;
      }
  
      // Reference to the specific patient document
      const patientRef = doc(db, "clinics", clinicId, "patients", patient.id);
  
      try {
          // Fetch the current patient document to check if the exercise is already assigned
          const docSnap = await getDoc(patientRef);
          if (docSnap.exists()) {
              const { assigned_exercises = [] } = docSnap.data();
              const isExerciseAlreadyAssigned = assigned_exercises.some(ex => ex.name === exercise.name);
  
              if (isExerciseAlreadyAssigned) {
                  // Exercise is already assigned, show an alert
                  alert(`${exercise.name} is already assigned to ${patient.first_name} ${patient.last_name}.`);
                  return; // Exit the function to prevent adding the exercise again
              }
  
              // Prepare the new exercise object with additional properties
              const newExercise = {
                  name: exercise.name,
                  tips: exercise.tips || [], // Ensure tips is an array
                  url: exercise.url || "" // Ensure url_string is a string
              };
  
              // Update the patient document by appending the new exercise object
              await updateDoc(patientRef, {
                  assigned_exercises: arrayUnion(newExercise) // Append new exercise
              });
  
              // Close the modal after adding
              setExercisePickerOpen(false);
          } else {
              console.log("No such document!");
          }
      } catch (error) {
          console.error("Error updating document: ", error);
      }
  };
  
    
    const handleAddSurvey = async (survey) => {
      // Validate clinicId and patient.id
      if (!clinicId || !patient.id) {
          console.error("Invalid clinicId or patient.id");
          return;
      }
  
      // Reference to the specific patient document
      const patientRef = doc(db, "clinics", clinicId, "patients", patient.id);
  
      try {
          // Fetch the current patient document to check if the survey is already assigned
          const docSnap = await getDoc(patientRef);
          if (docSnap.exists()) {
              const { assigned_surveys = [] } = docSnap.data();
  
              // Check if the survey is already assigned
              const isSurveyAlreadyAssigned = assigned_surveys.some(
                  (assignedSurvey) => assignedSurvey.configuration.name === survey.configuration.name
              );
  
              if (isSurveyAlreadyAssigned) {
                  // Survey is already assigned, show an alert
                  alert(`${survey.configuration.name} is already assigned to ${patient.first_name} ${patient.last_name}.`);
                  return; // Exit the function to prevent adding the survey again
              }
  
              // Validate the survey fields to avoid undefined values
              if (!survey.configuration?.name || !Array.isArray(survey.configuration.questions)) {
                  throw new Error("Invalid survey configuration. Name or questions are missing.");
              }
  
              // Ensure each question has valid fields and includes the 'style' property
              const validatedQuestions = survey.configuration.questions.map((question) => ({
                  question: question.question || "No question text",  // Ensure the question text exists
                  style: question.style || "Unknown style"  // Ensure the style exists
              }));
  
              // Append new survey object to the assigned_surveys array
              const newSurvey = {
                  configuration: {
                      name: survey.configuration.name,  // Ensure the survey name is added
                      questions: validatedQuestions     // Include both question and style properties
                  }
              };
  
              // Log the survey object to be uploaded for further validation
              console.log("Survey to be uploaded:", newSurvey);
  
              // Update the patient document with the new assigned_surveys array
              await updateDoc(patientRef, {
                  assigned_surveys: arrayUnion(newSurvey) // Use arrayUnion to avoid duplicates
              });
  
              // Close the modal after adding
              setSurveyPickerOpen(false);
          } else {
              console.log("No such document!");
              alert("No such document found.");
          }
      } catch (error) {
          console.error("Error updating document: ", error);
          alert(`Error updating document: ${error.message}`);
      }
  };
  
  

  
  const handleDeleteExercise = async (exerciseName) => {
    // Show a confirmation alert
    const confirmDelete = window.confirm(`Are you sure you want to unassign ${exerciseName} from ${patient.first_name} ${patient.last_name}?`);
  
    if (confirmDelete) {
      // Validate clinicId and patient.id
      if (!clinicId || !patient.id) {
        console.error("Invalid clinicId or patient.id");
        return;
      }
  
      // Reference to the specific patient document
      const patientRef = doc(db, "clinics", clinicId, "patients", patient.id);
  
      try {
        // Get the current patient document
        const patientDoc = await getDoc(patientRef);
  
        if (!patientDoc.exists()) {
          console.error("No such document!");
          return;
        }
  
        // Extract the current assigned exercises
        const patientData = patientDoc.data();
        const currentExercises = patientData.assigned_exercises || [];
  
        // Filter out the exercise to be removed
        const updatedExercises = currentExercises.filter(exercise => exercise.name !== exerciseName);
  
        // Update the patient document with the filtered exercises
        await updateDoc(patientRef, {
          assigned_exercises: updatedExercises
        });
  
        // Refresh the assignedExercises state
        setAssignedExercises(prev => prev.filter(exercise => exercise.name !== exerciseName));
  
        // Optionally, you might want to update the UI or give feedback to the user
        console.log(`Unassigned ${exerciseName} from ${patient.first_name} ${patient.last_name}`);
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  };
  
    const handleRemoveSurvey = async (surveyToRemove) => {
      if (!clinicId || !patient.id) {
        console.error("Invalid clinicId or patient.id");
        return;
      }
    
      // Show a confirmation dialog
      const confirmRemove = window.confirm(`Are you sure you want to remove the survey "${surveyToRemove.configuration.name}" for this patient?`);
    
      if (!confirmRemove) {
        // If the user cancels, simply return
        return;
      }
    
      const patientRef = doc(db, "clinics", clinicId, "patients", patient.id);
    
      try {
        // Fetch the current patient document
        const docSnap = await getDoc(patientRef);
        if (docSnap.exists()) {
          const { assigned_surveys = [] } = docSnap.data();
    
          // Check if the survey exists in the assigned surveys
          const isSurveyAssigned = assigned_surveys.some(survey =>
            survey.configuration.name === surveyToRemove.configuration.name
          );
    
          if (!isSurveyAssigned) {
            console.error("Survey is not assigned to the patient.");
            return;
          }
    
          // Remove the survey from the assigned_surveys array
          await updateDoc(patientRef, {
            assigned_surveys: arrayRemove(surveyToRemove)
          });
    
          // Update the state to remove the survey
          setAssignedSurveys(prev => prev.filter(survey => survey.configuration.name !== surveyToRemove.configuration.name));
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    };
    
  
    return (
      <Box>
        <Typography variant="h3" gutterBottom className="active-font">
          {patient.first_name} {patient.last_name}
        </Typography>
        <MessagingComponent patient={patient} db={db} clinicId={clinicId} />
        <Box display="flex" alignItems="center" style={{ marginTop: "50px" }}>
          <Typography className="basefont-regular" variant="h6">
            Assigned Exercises
          </Typography>
          <IconButton
            style={{ marginLeft: "8px" }}
            aria-label="add"
            onClick={() => setExercisePickerOpen(true)} // Open the modal
          >
            <AddIcon />
          </IconButton>
        </Box>
        <List>
          {assignedExercises && assignedExercises.map((exercise, index) => (
            <ListItem key={index} secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteExercise(exercise.name)}>
                <DeleteIcon />
              </IconButton>
            }>
              <ListItemText
                primary={<Typography className="basefont-regular">{exercise.name}</Typography>} // Apply the class here
              />
            </ListItem>
          ))}
        </List>
  
        <Box style={{ marginTop: "50px" }}>
    <Box display="flex" alignItems="center">
      <Typography className="basefont-regular" variant="h6">
        Assigned Surveys
      </Typography>
      <IconButton
        style={{ marginLeft: "8px" }}
        aria-label="add"
        onClick={() => setSurveyPickerOpen(true)} // Open the SurveyPicker modal
      >
        <AddIcon />
      </IconButton>
    </Box>
    <List>
      {assignedSurveys && assignedSurveys.map((survey, index) => (
        <ListItem key={index}>
          <ListItemText
            primary={<Typography className="basefont-regular">{survey.configuration.name}</Typography>}
          />
            <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleRemoveSurvey(survey)}
              >
                 <DeleteIcon />
              </IconButton>
        </ListItem>
      ))}
    </List>
  </Box>
         {/* Previous Responses Section */}
         <Box style={{ marginTop: "50px" }}>
          <Typography className="basefont-regular" variant="h6">
            Previous Responses
          </Typography>
          {patient.survey_responses && patient.survey_responses.map((response, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="basefont-regular">Survey {index + 1}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {Object.entries(response).map(([question, answer], i) => (
                  <Typography key={i} className="basefont-regular">
                    <strong>{question}:</strong> {answer}
                  </Typography>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <Button
          onClick={() => setOpenPatientReassign(true)}
          variant="outlined"
          className="active-font"
          style={{
              marginTop: "50px",
              marginBottom: "60px",
              borderColor: "#4BD891",
              color: "black",
              fontSize: "1.0rem",
              minWidth: "140px",
              height: "40px",
              }}>
            Re-Assign Patient
          </Button>
        <Dialog open={openPatientReassign} onClose={() => setOpenPatientReassign(false)}>
          <ReassignPatient selectedClinic={clinicId} providerID={sessionStorage.getItem("userUID")} db={db} patientID={patient.id} />
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPatientReassign(false)} 
          variant="outlined"
          className="active-font"
          style={{
              marginTop: "50px",
              marginBottom: "20px",
              borderColor: "#4BD891",
              color: "black",
              fontSize: "1.0rem",
              minWidth: "140px",
              height: "40px",
              }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
        <ExercisePicker
          open={isExercisePickerOpen}
          onClose={() => setExercisePickerOpen(false)}
          onAddExercise={handleAddExercise}
          db={db}
        />
        <SurveyPicker
          open={isSurveyPickerOpen}
          onClose={() => setSurveyPickerOpen(false)}
          onAddSurvey={handleAddSurvey} 
          db={db}
        />
      </Box>
    );
  };
  export default PatientOverview;