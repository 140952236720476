import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { initializeApp } from "firebase/app";
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, updatePassword, signInWithEmailAndPassword } from "firebase/auth";
import VitalQueTextField from "../landing-page/VitalQueTextField";
import { Dialog, DialogTitle, DialogContent, DialogActions, Menu } from '@mui/material';
import MenuPane from "./MenuPane";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const Settings = () => {
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [open, setOpen] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    if (newPassword.length < 6) {
      setError('New password must be at least 6 characters long.');
      return;
    }

    try {
      // Sign in with current credentials
      await signInWithEmailAndPassword(auth, email, currentPassword);

      // Get the current user
      const user = auth.currentUser;

      // Reauthenticate the user
      const credential = EmailAuthProvider.credential(email, currentPassword);
      await reauthenticateWithCredential(user, credential);

      // Update the password
      await updatePassword(user, newPassword);

      setSuccess('Password updated successfully.');
    } catch (err) {
      setError('Failed to update password: ' + err.message);
    }
  };

  return (
    <Grid container direction="column" style={{ minHeight: "100vh" }}>
    {/* MenuPane */}
    <MenuPane />
    <Grid container direction="column" alignItems="center" spacing={2} style={{ padding: '20px', marginTop: "85px" }}>

      <Button onClick={() => setOpen(true)} variant="outlined" className="active-font" style={{ marginBottom: '20px', borderColor: '#4BD891', color: 'black' }}>
        Change Password
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <VitalQueTextField
              fullWidth
              variant="outlined"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ marginBottom: '10px' }}
            />
            <VitalQueTextField
              fullWidth
              variant="outlined"
              label="Current Password"
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              sx={{ marginBottom: '10px' }}
            />
            <VitalQueTextField
              fullWidth
              variant="outlined"
              label="New Password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              sx={{ marginBottom: '10px' }}
            />
            {error && (
              <Typography variant="body2" color="error" className="basefont-regular" style={{ marginBottom: '10px' }}>
                {error}
              </Typography>
            )}
            {success && (
              <Typography variant="body2" color="success" className="basefont-regular" style={{ marginBottom: '10px' }}>
                {success}
              </Typography>
            )}
            <DialogActions>
              <Button onClick={() => setOpen(false)} variant="outlined" className="active-font" style={{ marginTop: "20px", borderColor: "#4BD891", color: "black" }}>
                Close
              </Button>
              <Button type="submit" variant="outlined" className="active-font" style={{ marginTop: "20px", borderColor: "#4BD891", color: "black" }}>
                Change Password
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Grid>
    </Grid>
  );
};

export default Settings;
