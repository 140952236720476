import React, { useEffect } from "react";
import "./HomePage.css";
import { Grid } from '@mui/material';
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import logo from "../images/logo.svg";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { Link } from "react-router-dom"; 

const PrivacyPolicy = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []); // Empty dependency array ensures the effect runs only once when the component mounts
    return (
        <Grid
            container
            spacing={0}
            justify="center"
            alignItems="center"
            style={{ minHeight: "100vh", padding: "13px" }}
        >
            <Grid item xs={12} container direction="column" alignItems="center" style={{ marginBottom: "200px" }}>
                <Header/>
            </Grid>
            <Grid item xs={12} container direction="column" alignItems="center">
                <div style={{ textAlign: "leading", padding: "30px"}}>
                    <Typography variant="h1" className="basefont-bold" style={{ marginBottom: "20px" }}>
                        Privacy Policy
                    </Typography>
                    <Typography variant="h5" className="basefont-bold" style={{ marginBottom: "20px" }}>
                        This Privacy Policy describes how VitalQue collects, uses, and protects the personal information you provide on our Get Started page.
                    </Typography>
                    <Typography variant="h6" className="basefont-bold" style={{ marginBottom: "10px" }}>
                        Information We Collect:
                    </Typography>
                    <Typography variant="body" className="basefont-regular" style={{ marginBottom: "10px" }}>
                        <ul>
                            <li>First Name</li>
                            <li>Last Name</li>
                            <li>Phone Number</li>
                            <li>Email Address</li>
                            <li>Company Name</li>
                        </ul>
                    </Typography>
                    <Typography variant="h6" className="basefont-bold" style={{ marginBottom: "10px" }}>
                        How We Use Your Information:
                    </Typography>
                    <Typography variant="body" className="basefont-regular" style={{ marginBottom: "10px" }}>
                        We use the information collected solely for the purpose of contacting you regarding your interest in our services. Your information helps us to respond to your inquiries and provide you with the best possible service.
                    </Typography>
                    <Typography variant="h6" className="basefont-bold" style={{ marginBottom: "10px" }}>
                        Protection of Your Information:
                    </Typography>
                    <Typography variant="body" className="basefont-regular" style={{ marginBottom: "10px" }}>
                        We are committed to protecting the security of your personal information. We employ industry-standard security measures to prevent unauthorized access, disclosure, alteration, or destruction of your information.
                    </Typography>
                    <Typography variant="h6" className="basefont-bold" style={{ marginBottom: "10px" }}>
                        Sharing Your Information:
                    </Typography>
                    <Typography variant="body" className="basefont-regular" style={{ marginBottom: "10px" }}>
                        We do not sell, trade, or otherwise transfer your personal information to outside parties. Your information is only shared with our team members who need it to respond to your inquiries or provide our services to you.
                    </Typography>
                    <Typography variant="h6" className="basefont-bold" style={{ marginBottom: "10px" }}>
                        Your Consent:
                    </Typography>
                    <Typography variant="body" className="basefont-regular" style={{ marginBottom: "10px" }}>
                        By providing your personal information on our Get Started page, you consent to the collection, use, and disclosure of that information as described in this Privacy Policy.
                    </Typography>
                    <Typography variant="h6" className="basefont-bold" style={{ marginBottom: "10px" }}>
                        Changes to This Privacy Policy:
                    </Typography>
                    <Typography variant="body" className="basefont-regular" style={{ marginBottom: "20px" }}>
                        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will post any changes to this Privacy Policy on this page.
                    </Typography>
                </div>
            </Grid>
            <Footer/>
        </Grid>
    );
};

export default PrivacyPolicy;
