import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, collection, getDocs, updateDoc,  arrayUnion, arrayRemove, setDoc, onSnapshot } from "firebase/firestore";
import MenuPane from "./MenuPane";
import VitalQueTextField from "../landing-page/VitalQueTextField";
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem'; 
import ListItemText from '@mui/material/ListItemText'; 
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import { Divider } from "@mui/material";

const ExercisePicker = ({ open, onClose, onAddExercise, db }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [exercises, setExercises] = useState([]);

  useEffect(() => {
    if (open) {
      // Fetch exercises from Firebase
      const fetchExercises = async () => {
        const docRef = doc(db, "exercises", "DgHTBVq1f9hBuQ0tcUSU"); // Use doc() to reference the document
        try {
          const docSnap = await getDoc(docRef); // Use getDoc to get the document
          if (docSnap.exists()) {
            const { pt = [], slp = [], respiratory = [] } = docSnap.data();
            const allExercises = [...pt, ...slp, ...respiratory];
            setExercises(allExercises);
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching document: ", error);
        }
      };
      fetchExercises();
    }
  }, [open]);

  const filteredExercises = exercises.filter(exercise =>
    exercise.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Box padding={2}>
        <VitalQueTextField
          label="Search Exercises"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        <List>
          {filteredExercises.map((exercise, index) => (
            <Box key={index} mb={2}>
              <ListItem button onClick={() => onAddExercise(exercise)}>
                <ListItemText
                  primary={<Typography variant="h6" className="basefont-regular">{exercise.name}</Typography>}
                  secondary={
                    <>
                      {/* Display the exercise URL if available */}
                      {exercise.url && (
                        <Box mt={1}>
                          <Typography variant="body2" color="textSecondary" className="basefont-regular">
                            Video: <a href={exercise.url} target="_blank" rel="noopener noreferrer">{exercise.url}</a>
                          </Typography>
                        </Box>
                      )}
                      
                      {/* Display exercise tips */}
                      {exercise.tips && exercise.tips.length > 0 && (
                        <Box mt={1}>
                          <Typography variant="subtitle2" className="basefont-regular">
                            Tips:
                          </Typography>
                          <ul>
                            {exercise.tips.map((tip, tipIndex) => (
                              <li key={tipIndex}>
                                <Typography variant="body2" color="textSecondary" className="basefont-regular">
                                  {tip}
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        </Box>
                      )}
                    </>
                  }
                />
              </ListItem>
              <Divider />
            </Box>
          ))}
        </List>
      </Box>
    </Dialog>
  );
};

export default ExercisePicker;